<template>
  <docs-layout>
    <p>We use <asom-link href="https://css.gg/" target="blank">CSS.GG</asom-link> for our icon set</p>
    <h1 class="text-3xl font-bold my-4">All icons</h1>
    <div class="space-y-4 pb-10 w-full grid sm:grid-cols-6 md:grid-cols-4 gap-4">
      <div v-for="(icon, i) in icons" :key="i" class="flex justify-start w-full items-center space-x-4">
        <asom-icon :icon="icon">
        </asom-icon>
        <div class="flex-1 text-left">
          <span>{{icon}}</span>
        </div>
      </div>
    </div>
    <h1 class="text-3xl font-bold mb-4">All icon sizes</h1>
    <div class="space-y-4 pb-10 w-full">
      <div v-for="(size, i) in sizes" :key="i" class="flex justify-start w-full items-center space-x-4">
        <asom-icon icon="user" :size="size">
        </asom-icon>
        <div class="flex-1 text-left">
          <span>Size {{size}}</span>
        </div>
      </div>
    </div>
    <h1 class="text-3xl font-bold mb-4">Colors</h1>
    <p>Colors can be applied via tailwind.css classes <pre class="contents">text-{color-code}</pre></p>
    <div class="space-y-4 pb-10 w-full text-left">
      <asom-icon icon="user" class="text-blue-500" size="lg" />
      <asom-icon icon="user" class="text-red-500" size="lg" />
      <asom-icon icon="user" class="text-green-500" size="lg" />
      <asom-icon icon="user" class="text-purple-500" size="lg" />
    </div>
  </docs-layout>
</template>
<script>
import ICON_SET from '@/constants/ICON_SET'
import DocsLayout from '@/docs/DocsLayout.vue'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      icons: Object.keys(ICON_SET),
      sizes: [ 'sm', 'md', 'lg' ]
    }
  }
}
</script>